import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import SEO from "../components/seo"
const slugify = require("slugify")
import pergolaImage from "../images/portfolio/pergola.jpg"
import latticeImage from "../images/portfolio/lattice.jpg"
import gardenImage from "../images/portfolio/garden.jpg"
import fencingImage from "../images/portfolio/fencing.jpg"
import outdoorLightingImage from "../images/portfolio/outdoor-lighting.jpg"
import outdoorShowerImage from "../images/portfolio/outdoor-showers.jpg"
import shedsImage from "../images/portfolio/sheds.jpg"
import childrensImage from "../images/portfolio/childrens.jpg"
import alFrescoImage from "../images/portfolio/al-fresco.jpg"
import mailImage from "../images/portfolio/mail-lanterns.jpg"
import railingsImage from "../images/portfolio/railings.jpg"
import kitchensImage from "../images/portfolio/outdoor-kitchen.jpg"

const CustomProjectsPage = () => {
  const data = useStaticQuery(graphql`
    {
      allPortfolio {
        edges {
          node {
            id
            slug
            category
            categorySlug
            title
          }
        }
      }
    }
  `)

  const categories = [
    ...new Set(data.allPortfolio.edges.map(({ node }) => node.category)),
  ]

  categories.remove

  const categoryImages = [
    { name: "Fencing & Enclosures", image: fencingImage },
    { name: "Garden Decor", image: gardenImage },
    { name: "Outdoor Lightning", image: outdoorLightingImage },
    { name: "Pergolas & Arbors", image: pergolaImage },
    { name: "Lattice & Privacy Screens", image: latticeImage },
    { name: "Outdoor Showers", image: outdoorShowerImage },
    { name: "Sheds & Workshops", image: shedsImage },
    { name: "Children's Playsets", image: childrensImage },
    { name: "Al Fresco Dining", image: alFrescoImage },
    { name: "Mail & Lantern Posts", image: mailImage },
    { name: "Railings", image: railingsImage },
    { name: "Outdoor Kitchens", image: kitchensImage },

    { name: "Unknown", image: fencingImage },
  ]

  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Custom Shed, Fence, Lattice, Trellis | Walpole Woodworkers`}
        description={`Walpole Outdoors can use your sketches and descriptions to create designs for sheds, trellis, pergolas, and swing sets. Schedule a Design Consultation`}
      />

      {/* CONTENT GOES HERE */}
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/custom-projects">Custom Projects</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="portfolio-header">
          <h1 className="generalheader">Custom Projects</h1>
          <p className="header-intro">
            Walpole brings your ideal outdoors to life. Whether your project is
            a single piece or a complete transformation of your outdoor
            environment, our consultants and artisans can use your descriptions,
            sketches, or architectural drawings as our guide to fulfill your
            vision. Walpole can also create designs to complement your existing
            architecture or personal style.
          </p>
        </Row>

        <Row className="portfolio-grid">
          {categories.map((category) => {
            if (category === "Unknown" || category === "Other") return

            let slug = slugify(category, {
              lower: true,
              remove: /[*+~.()'"!:@]/g,
              strict: true,
            })
            let catImage = categoryImages.filter(
              (catImage) => catImage.name === category
            )[0]

            if (!catImage)
              catImage = categoryImages.filter(
                (catImage) => catImage.name === "Unknown"
              )[0]

            return (
              <Col key={category} md={6} className="item portfolio-th">
                <Link className="_link" to={`/custom-projects/${slug}`}></Link>
                <figure
                  style={{
                    background: `linear-gradient(
                    rgba(21, 21, 21, 0.45), 
                    rgba(21, 21, 21, 0.45)
                  ),url(${catImage.image}) center center/cover no-repeat`,
                  }}
                ></figure>
                <h2>{category}</h2>
              </Col>
            )
          })}
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default CustomProjectsPage
